import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {
    userName: "",
    id: "",
    token: "",
    isLoggedIn: false,
  },
  session: {
    id: "",
    guestId: "",
  },
};

const endState = {
  user: {
    userName: "",
    id: "",
    token: "",
    isLoggedIn: false,
  },
  session: {
    id: "",
    guestId: "",
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signingIn: (state, action) => ({
      ...state,
      user: {
        userName: action.payload.email,
        token: action.payload.access_token,
        id: action.payload.user_id,
        isLoggedIn: true,
      },
    }),

    storeSession: (state, action) => ({
      ...state,
      session: { id: action.payload },
    }),
    storeGuestSession: (state, action) => ({
      ...state,
      session: { guestId: action.payload },
    }),

    logOut: () => endState,
  },
});

export const { signingIn, storeSession, logOut, storeGuestSession } =
  authSlice.actions;

export default authSlice.reducer;
