import React from "react";
import PropTypes from "prop-types";

const Wrapper = ({ children }) => (
  <div className="sm:max-h-[98vh] sm:mt-0 mt-24 w-full overflow-hidden">
    {children}
  </div>
);

Wrapper.propTypes = {
  children: PropTypes.element.isRequired,
};
export default Wrapper;
