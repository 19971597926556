import React, { useState } from "react";
import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BeatLoader } from "react-spinners";

const TableList = ({
  lawyer_name,
  lawyer_state,
  lawyer_id,
  lawyer_img,
  lawyer_phone,
  lawyer_website,
  lawyer_role,
  lawyer_exp,
}) => {
  const navigate = useNavigate();

  const [imageLoaded, setImageLoaded] = useState(false);
  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  return (
    <div
      className="lawyer-detail text-[15px] cursor-pointer"
      onClick={() => navigate(`/home/lawyer/${lawyer_id}`)}
    >
      {!imageLoaded ? (
        <div className="loader-container">
          <BeatLoader
            className="flex justify-center items-center"
            color="#f1f1f1"
            size={5}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : null}

      <img
        src={lawyer_img}
        className={`sm:h-14 sm:w-14 h-12 w-14 object-cover rounded-full ${
          !imageLoaded ? "hidden" : ""
        }`}
        alt={lawyer_name}
        onLoad={handleImageLoad}
      />
      <div className="table-col">
        <h2 className="font-medium">{lawyer_name}</h2>
        <span className="flex gap-1 items-center">
          <FaMapMarkerAlt size={12} />
          <h2 className="text-[13px] text-light">{lawyer_state}</h2>
        </span>
      </div>
      <div className="table-col">
        <h2>{lawyer_role}</h2>
        <span className="flex gap-1 items-center">
          <h2 className="text-[13px] text-light">Exp. {lawyer_exp}</h2>
        </span>
      </div>
      <div className="table-col">
        <h2 className="text-[13px] text-light">{lawyer_website}</h2>
      </div>
      <div className="table-col">
        <h2 className="text-[13px] text-light">{lawyer_phone}</h2>
      </div>
    </div>
  );
};

TableList.propTypes = {
  lawyer_name: PropTypes.string,
  lawyer_state: PropTypes.string,
  lawyer_id: PropTypes.number,
  lawyer_img: PropTypes.string,
  lawyer_phone: PropTypes.string,
  lawyer_website: PropTypes.string,
  lawyer_role: PropTypes.string,
  lawyer_exp: PropTypes.number,
};

TableList.defaultProps = {
  lawyer_name: "",
  lawyer_state: "",
  lawyer_id: "",
  lawyer_img: "",
  lawyer_phone: "",
  lawyer_website: "",
  lawyer_role: "",
  lawyer_exp: 0,
};

export default TableList;
