import React from "react";
import PropTypes from "prop-types";
import prompts from "../statics";

const Prompts = ({ handlePromptQuery }) => (
  <div className="prompts-wrapper">
    <h2 className="title">Examples</h2>
    <div className="prompt-box">
      {prompts.map((prompt) => (
        <div
          key={prompt}
          className="prompt"
          onClick={() => {
            handlePromptQuery(prompt);
          }}
        >
          {prompt}
        </div>
      ))}
    </div>
  </div>
);

Prompts.propTypes = {
  handlePromptQuery: PropTypes.func,
};

Prompts.defaultProps = {
  handlePromptQuery: "",
};
export default Prompts;
