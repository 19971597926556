import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import sha1 from "js-sha1";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { MdEmail, MdPassword } from "react-icons/md";
import { FaSignInAlt } from "react-icons/fa";
import axios from "axios";
import { signingIn } from "../features/authSlice";
import { landingSignupIllustration } from "../assets";

const schema = yup.object().shape({
  email: yup.string().email().required("Email is required"),
  password: yup.string().required("Password is required"),
});

const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const signIn = async ({ email, password }) => {
      setLoading(true);
      const hashedPass = sha1(password);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_API_URL}/api/v1/user/signin`,

          {
            email,
            password: hashedPass,
          }
        );

        if (response.statusText === "OK") {
          const { access_token } = response.data.data;
          const { user_id } = response.data.data;

          dispatch(signingIn({ email, access_token, user_id }));

          toast.success("Sign-in successful!");
          setLoading(false);
          navigate("/home");
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 409) {
            toast.error(`Conflict Error`);
          } else if (error.response.status === 401) {
            toast.error(`${error.response.data.errorData.message}`);
          } else {
            toast.error(`${error.response.data.errorData.message}`);
          }
        } else if (error.request) {
          toast.error(`No Response received`);
        } else {
          toast.error(`Error${error.response.data.errorData.message}`);
        }
        setLoading(false);
      }
    };

    signIn(data);
  };

  return (
    <div className="signin-wrapper">
      <div className="form-wrapper">
        <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="flex gap-2 justify-center items-center text-3xl text-bold text-primary my-3">
            <FaSignInAlt size={32} color="#E9FE53" />
            Sign In
          </h2>
          <div className="signin-input-wrapper">
            <MdEmail className="signin-icon" color="#E9FE53" size={18} />
            <input
              className={`signin-input ${errors.email && "invalid-input"}`}
              type="email"
              {...register("email")}
              placeholder="Enter Your email..."
              autoComplete="off"
            />
          </div>
          {errors.email && (
            <span className="error-message">{errors.email.message}</span>
          )}

          <div className="signin-input-wrapper">
            <MdPassword className="signin-icon" color="#E9FE53" size={18} />

            <input
              className={`signin-input ${errors.password && "invalid-input"}`}
              type="password"
              {...register("password")}
              placeholder="Enter Your Password..."
            />
          </div>
          {errors.password && (
            <span className="error-message">{errors.password.message}</span>
          )}
          <button className="signin-btn" type="submit">
            {loading ? "Signing In..." : "Sign In"}
          </button>
          <span className="signin-info">
            <p>
              Dont have an account?{" "}
              <Link className="link" to="/register">
                Signup here!
              </Link>
            </p>
          </span>
        </form>
        <div
          className={`${
            location.pathname === "/signin"
              ? "signup-img"
              : "signup-img-landing"
          }`}
        >
          <img
            src={landingSignupIllustration}
            alt="landingSignupIllustration.png"
          />
        </div>
      </div>
    </div>
  );
};
export default SignIn;
