import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chats: [],
  recentChat: [],

  chatHistory: [],
};

const chatSlice = createSlice({
  name: "chat",
  initialState,

  reducers: {
    addNewChat: (state, action) => {
      state.chats.push(action.payload);
    },
    addChatToHistory: (state, action) => ({
      ...state,
      recentChat: action.payload,
    }),
    showHistory: (state, action) => ({
      ...state,
      chatHistory: action.payload,
    }),
    // appendWithHistory: (state, action) => {
    //   state.chatHistory.push(action.payload);
    // },

    appendWithHistory: (state, action) => {
      const { _id, message } = action.payload;
      const chatIndex = state.chatHistory.findIndex((chat) => chat._id === _id);

      if (chatIndex !== -1) {
        const updatedChatHistory = state.chatHistory.map((chat, index) =>
          (index === chatIndex
            ? { ...chat, message: chat.message + message }
            : chat)
        );

        return { ...state, chatHistory: updatedChatHistory };
      }
      // else....
      return {
        ...state,
        chatHistory: [...state.chatHistory, action.payload],
      };
    },

    clearChat: (state) => ({ ...state, chats: [] }),
  },
});

export const {
  addNewChat,
  addChatToHistory,
  showHistory,
  appendWithHistory,
  clearChat,
} = chatSlice.actions;
export default chatSlice.reducer;
