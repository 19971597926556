import React from "react";
import { FaBuilding, FaNewspaper, FaPhoneAlt, FaUser } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";

const RequestDemo = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form className="bookDemo-form" onSubmit={handleSubmit}>
      <div className="signup-input-wrapper">
        <FaUser className="signup-icon" color="#E9FE53" size={15} />
        <input
          className="signup-input"
          type="text"
          placeholder="Enter Name..."
          autoComplete="off"
        />
      </div>
      <div className="signup-input-wrapper">
        <MdAlternateEmail className="signup-icon" color="#E9FE53" size={15} />
        <input
          className="signup-input"
          type="email"
          placeholder="Enter email..."
          autoComplete="off"
        />
      </div>
      <div className="signup-input-wrapper">
        <FaNewspaper className="signup-icon" color="#E9FE53" size={15} />
        <input
          className="signup-input"
          type="text"
          placeholder="Enter Job Title..."
          autoComplete="off"
        />
      </div>

      <div className="signup-input-wrapper">
        <FaBuilding className="signup-icon" color="#E9FE53" size={15} />
        <input
          className="signup-input "
          type="text"
          placeholder="Enter Company Name..."
        />
      </div>

      <div className="signup-input-wrapper">
        <FaPhoneAlt className="signup-icon" color="#E9FE53" size={15} />
        <input
          className="signup-input"
          type="number"
          placeholder="Enter Phone Number..."
        />
      </div>

      <button className="bookDemo-btn" type="submit">
        Request Demo
      </button>
    </form>
  );
};

export default RequestDemo;
