import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { IoSend } from "react-icons/io5";
import { BeatLoader } from "react-spinners";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";

import ChatBox from "./ChatBox";
import Prompts from "./Prompts";

import { logOut, storeSession } from "../features/authSlice";
import { addChatToHistory } from "../features/chatSlice";

const Dashboard = () => {
  const [query, setQuery] = useState("");
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { authSlice, chatSlice } = store;

  const [chats, setChat] = useState([]);

  const chatBoxRef = useRef();
  const inputRef = useRef();
  const socketRef = useRef(null);

  const navigate = useNavigate();

  const userId = authSlice.user.id;
  const sessionId = authSlice.session.id;
  const { token } = authSlice.user;

  const createSession = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const session_name = "test";

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/session?session_name=${session_name}`,
        {},
        { headers }
      );

      if (response.statusText === "OK") {
        const { session_id } = response.data.data;
        dispatch(storeSession(session_id));
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 409) {
          dispatch(logOut());
          navigate("/");
          toast.error(`${error.response.data.errorData.message}`);
        } else if (error.response.status === 401) {
          dispatch(logOut());
          navigate("/");
          toast.error(`${error.response.data.errorData.message}`);
        } else {
          dispatch(logOut());
          navigate("/");
          toast.error(`${error.response.data.errorData.message}`);
        }
      } else if (error.request) {
        dispatch(logOut());
        navigate("/");
        toast.error(`No Response received`);
      } else {
        dispatch(logOut());
        navigate("/");
        toast.error(`No Response received`);
      }
    }
  };

  const getSession = async () => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const page = 1;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/session?page=${page}`,
        { headers }
      );

      if (response.statusText === "OK") {
        const { data } = response.data;
        dispatch(addChatToHistory(data));
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 409) {
          dispatch(logOut());
          navigate("/");
          toast.error(`${error.response.data.errorData.message}`);
        } else if (error.response.status === 401) {
          dispatch(logOut());
          navigate("/");
          toast.error(`${error.response.data.errorData.message}`);
        } else {
          dispatch(logOut());
          navigate("/");
          toast.error(`${error.response.data.errorData.message}`);
        }
      } else if (error.request) {
        dispatch(logOut());
        navigate("/");
        toast.error(`No Response received`);
      } else {
        dispatch(logOut());
        navigate("/");
        toast.error(`No Response received`);
      }
    }
  };

  useEffect(() => {
    setChat([]);
  }, [chatSlice.chats]);

  useEffect(() => {
    if (token && !sessionId) {
      createSession();
    }
    getSession();
  }, [dispatch]);

  useEffect(() => {
    socketRef.current = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET_BASE_API_URL}/ws/${sessionId}/${userId}`
    );

    socketRef.current.onopen = () => {};

    socketRef.current.onmessage = (event) => {
      const message = event.data;

      const msgObj = JSON.parse(message);

      if (msgObj.type === "start") {
        setLoading(true);
      }

      if (msgObj.type === "stream") {
        if (msgObj.sender_type === "BOT") {
          setChat((prevChat) => {
            const updatedChat = [...prevChat];

            const lastChatIndex = updatedChat.length - 1;

            if (lastChatIndex >= 0) {
              updatedChat[lastChatIndex] = {
                ...updatedChat[lastChatIndex],
                userQuery: query,
                response: {
                  ...updatedChat[lastChatIndex].response,
                  message:
                    updatedChat[lastChatIndex].response.message +
                    msgObj.message,
                },
                isCompleted: false,
              };
            } else {
              updatedChat.push({
                id: uuidv4(),
                userQuery: query,
                response: {
                  message: msgObj.message,
                },
                isCompleted: false,
              });
            }

            return updatedChat;
          });
        }
        setQuery("");
      }

      if (msgObj.type === "end") {
        setLoading(false);
        setQuery("");
        setChat((prevChat) => [
          ...prevChat,
          {
            userQuery: "",
            response: {
              message: "",
            },
            isCompleted: true,
          },
        ]);
        getSession();
      }
    };

    socketRef.current.onclose = () => {};

    return () => {
      if (socketRef.readyState === 1) {
        socketRef.close();
      }
    };
  }, [query]);

  useEffect(() => {
    chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
  }, [chats]);

  const handleChange = (e) => {
    setQuery(e.target.value);
    setInput(e.target.value);
  };

  const handleSubmit = (e) => {
    if (query.length < 2) {
      return;
    }
    e.preventDefault();

    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify({ message: query }));
    }

    setLoading(false);
  };

  const handlePromptQuery = (promptQuery) => {
    setQuery(promptQuery);
  };

  const handleEdit = () => {
    setQuery(input);
  };

  return (
    <main className="dashboard-wrapper">
      <div className="chat-box scroll-smooth" ref={chatBoxRef}>
        {chats.length > 0 ? (
          chats.map((chat) => {
            if (chat.isCompleted || !chat.response.message) {
              return null;
            }
            return (
              <ChatBox
                loading={loading}
                key={chat.id}
                query={query}
                chat1={chat}
                handleEdit={handleEdit}
              />
            );
          })
        ) : (
          <Prompts handlePromptQuery={handlePromptQuery} />
        )}
      </div>

      <form className="chat-form" onSubmit={handleSubmit}>
        <input
          ref={inputRef}
          className="chat-input"
          name="chatInput"
          value={query}
          type="text"
          onChange={handleChange}
          disabled={loading}
          autoComplete="off"
          placeholder="Write a prompt..."
        />

        {loading ? (
          <div className="loader">
            <BeatLoader
              color="#f1f1f1"
              loading={loading}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <div className="inp-btn">
            <IoSend
              className="bg-primary p-1 cursor-pointer"
              color="black"
              size={25}
              onClick={handleSubmit}
            />
          </div>
        )}
      </form>
    </main>
  );
};

export default Dashboard;
