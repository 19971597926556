import React from "react";
import { useNavigate } from "react-router-dom";

import { arrowBack } from "../assets";

const BackButton = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (window.location.pathname.includes("chat")) {
      navigate("/home");
      return;
    }
    navigate(-1);
  };

  return (
    <div className="back-btn-wrapper">
      <img
        className="cursor-pointer"
        src={arrowBack}
        alt="back-icon"
        onClick={handleGoBack}
      />
    </div>
  );
};

export default BackButton;
