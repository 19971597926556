import React from "react";

const Appointment = () => (
  <div className="appointment-wrapper">
    <header className="head">
      <h2 className="font-bold">My Appointments</h2>
      <button
        type="button"
        className="book-appointment-btn sm:text-base text-sm"
      >
        + Book New Appointment
      </button>
    </header>
    <div className="appointment-coming-soon">
      <div className="coming-soon-wrapper">
        <h2 className="animated-text text-3xl sm:text-6xl font-semibold">
          Coming Soon...!!!
        </h2>
      </div>
    </div>
  </div>
);

export default Appointment;
