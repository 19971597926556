import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import {
  LandingperfectForIcon1,
  LandingperfectForIcon2,
  LandingperfectForIcon3,
  landing1,
  landingCardIcon1,
  landingCardIcon2,
  landingCardIcon3,
  landingPageAILogo,
  landingStarIcon,
  landingpageFeatureImg,
  landingpageFeaturePattern,
  logo,
} from "../assets";
import RequestDemo from "./RequestDemo";
import TryCourtGPTDashboard from "./TryCourtGPTDashboard";

const LandingPage = () => {
  const [open, setOpen] = useState(false);
  const [connect, setConnect] = useState(false);

  const navigate = useNavigate();

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleConenctWithWebsocket = () => {
    setConnect(true);
  };

  return (
    <div className="landingPage-wrapper">
      <nav className="landing-nav ">
        <img
          src={logo}
          className="md:w-44 w-40 mx-1 cursor-pointer"
          alt="logo"
          onClick={handleScrollTop}
        />
        <ul className="sm:flex hidden md:gap-6 gap-4">
          <li onClick={handleScrollTop}> Home </li>
          <li>
            <a href="#feature-sec">Features</a>
          </li>
        </ul>

        <button
          className="landing-login-btn "
          type="button"
          onClick={() => navigate("/signin")}
        >
          Sign in
        </button>

        <FaBars
          size={22}
          className="sm:hidden flex cursor-pointer mx-1"
          color="#E9FE53"
          onClick={() => setOpen(!open)}
        />
        {open && (
          <div className="nav-dropdown">
            <span className="lists" onClick={handleScrollTop}>
              Home
            </span>
            <span className="lists">
              <a href="#feature-sec">Features </a>
            </span>
            <button
              className="landing-login-btn-mobile "
              type="button"
              onClick={() => navigate("/signin")}
            >
              Sign in
            </button>
          </div>
        )}
      </nav>
      <div className="hero">
        <div className="hero-title">
          <h2 className="sm:text-3xl text-2xl font-bold ">
            Discover a New Era of Legal Assistance
          </h2>
          <p className="text-sm sm:text-base text-light  ">
            Unlock the potential of hassle-free legal aid with Court-GPT. Wave
            goodbye to expensive consultations, frustrating appointment delays,
            and incomprehensible legal terminology.
          </p>
          <a href="#try-it">
            <button className="tryNow-btn" type="button">
              Try it Now
            </button>
          </a>
        </div>
        <div className="hero-img-wrapper">
          <img
            // className="lg:h-[500px] md:h-[420px] sm:h-[300px] h-full"
            src={landing1}
            alt="landing-img"
          />
        </div>

        {/* <div className="matrix-bg">
          <img className="matrix-bg-img" src={landingBg} alt="matrix-bg" />
        </div> */}
      </div>

      <div className="try-live-section" id="try-it">
        <h2 className=" sm:text-2xl text-xl font-semibold ">Try it Live</h2>
        <p className="text-light text-sm ">
          We have trained this Chatbot to answer questions about CourtGPT.
          <br />
          Ask question to get started with Courtgpt.
        </p>

        <div className="try-gpt-box max-w-6xl sm:w-90% w-[100%] min-h-[50vh]">
          {!connect && (
            <div className="try-connect-wrapper">
              <button
                className="try-connect-btn"
                type="button"
                onClick={handleConenctWithWebsocket}
              >
                Connect
              </button>
            </div>
          )}

          <TryCourtGPTDashboard connect={connect} />
        </div>
      </div>

      <div className="feature-section" id="feature-sec">
        <h3 className="sm:text-2xl text-xl font-semibold my-3">Features</h3>
        <div className="feature-boxes">
          <div className="feature-box">
            {/* <div className="feature-side-gradient" /> */}
            <div className="feature-box-pattern">
              <img
                className="feature-box-pattern-img"
                src={landingpageFeaturePattern}
                alt="landingpageFeaturePattern"
              />
            </div>
            <div className="feature-title">
              <h2 className="sm:text-2xl text-xl font-semibold">
                Unlock the Power of Legal Intelligence
              </h2>
              <p className="text-sm font-semibold text-light">
                Your All-in-One Solution for Prompt Answers, Expert Connections,
                and Informed Decisions!
              </p>
            </div>
            <img
              className="feature-box-img"
              src={landingpageFeatureImg}
              alt="feature-icon"
            />
          </div>
          <div className="feature-cards">
            <div className="feature-card">
              <div className="card-head flex gap-2 items-center">
                <img
                  className="card-icons"
                  src={landingCardIcon1}
                  alt="feature-icon"
                />
                <p className="font-semibold">
                  Prompt <br /> Legal Answers
                </p>
              </div>
              <div className="card-body mt-2">
                <p className="text-light text-sm">
                  Get instant answers to your legal questions with our web app.
                  Simply ask your prompt, and our AI-powered system will provide
                  you with accurate and reliable information in seconds.
                </p>
              </div>
            </div>
            <div className="feature-card">
              <div className="card-head flex gap-2 items-center">
                <img
                  className="card-icons"
                  src={landingCardIcon2}
                  alt="feature-icon"
                />
                <p className="font-semibold">
                  Lawyer <br /> Recommendations
                </p>
              </div>
              <div className="card-body mt-2">
                <p className="text-light text-sm">
                  Discover comprehensive and insightful legal guidance with our
                  cutting-edge AI algorithms, analyzing vast legal datasets to
                  deliver the most relevant and up-to-date information tailored
                  for you customers.
                </p>
              </div>
            </div>
            <div className="feature-card">
              <div className="card-head flex gap-2 items-center">
                <img
                  className="card-icons"
                  src={landingCardIcon3}
                  alt="feature-icon"
                />
                <p className="font-semibold">
                  AI-Powered <br /> Assistance
                </p>
              </div>
              <div className="card-body mt-2">
                <p className="text-light text-sm">
                  Untap a new market of potential customers through the
                  start-of-the-art legal recommendations. Elevate your customer
                  experience and expand your reach through CourtGPT.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="perfect-for-section">
        <h2 className="font-semibold sm:text-2xl text-xl text-center my-3">
          The Perfect Legal Assistant for
        </h2>

        <div className="perfect-for-box">
          <div className="box-card">
            <div className="perfect-for-icon">
              <img
                className="perfect-for-icon-img"
                src={LandingperfectForIcon1}
                alt="LandingperfectForIcon1"
              />
            </div>

            <h3 className="text-xl font-semibold text-center my-2">Lawyers</h3>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className="text-para">Efficient legal research tool</p>
            </span>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className="text-para">
                Referrals and connections with clients
              </p>
            </span>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className="text-para">
                Enhanced knowledge base for case handling
              </p>
            </span>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className=" text-para">
                Time-saving assistance for routine tasks
              </p>
            </span>
          </div>

          <div className="box-card">
            <div className="perfect-for-icon">
              <img
                className="perfect-for-icon-img"
                src={LandingperfectForIcon2}
                alt="LandingperfectForIcon2"
              />
            </div>

            <h3 className=" text-xl font-semibold text-center my-2">
              Students
            </h3>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className=" text-para">Learning resource for legal studies</p>
            </span>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className=" text-para">
                Practical application of legal concepts
              </p>
            </span>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className=" text-para">
                Guidance for research and assignments
              </p>
            </span>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className=" text-para">
                Exposure to real-life legal scenarios
              </p>
            </span>
          </div>

          <div className="box-card">
            <div className="perfect-for-icon">
              <img
                className="perfect-for-icon-img"
                src={LandingperfectForIcon3}
                alt="LandingperfectForIcon3"
              />
            </div>

            <h3 className=" text-xl font-semibold text-center my-2">You</h3>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className=" text-para">Quick access to legal answers</p>
            </span>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className=" text-para">
                Convenient guidance for legal queries
              </p>
            </span>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className=" text-para">Personalized lawyer recommendations</p>
            </span>
            <span className="flex gap-2 items-center">
              <img
                className="w-2 h-2 "
                src={landingStarIcon}
                alt="landingStarIcon"
              />
              <p className=" text-para">
                Simplified understanding of legal matters
              </p>
            </span>
          </div>
        </div>
      </div>

      <div className="try-gpt-bookDemo">
        <div className="bookDemo-wrapper">
          <div className="try-gpt-title">
            <h2 className="font-semibold sm:text-2xl text-xl">
              Stay Ahead in the Legal Landscape
            </h2>
            <p className="text-para sm:!text-base ">
              Request a demo now to stay ahead of the competition with our
              advanced solution tailored for you!
            </p>
          </div>
          <RequestDemo />
        </div>
      </div>

      <div className="separator" />
      <footer className="landing-footer">
        <div className="footer-upper sm:text-base text-sm">
          <img
            src={logo}
            className="md:w-32 w-28 mx-1"
            alt="logo"
            onClick={handleScrollTop}
          />
          <ul className="flex items-center md:gap-6 gap-4 ">
            <li onClick={handleScrollTop}> Home </li>
            <li>
              <a href="#feature-sec">Features</a>
            </li>
            <li> Terms </li>
            <li> Privacy policy </li>
          </ul>

          <p>© 2023 CourtGPT</p>
        </div>
        <div className="footer-lower">
          <p className="sm:text-base text-sm text-footer">
            Powered by AI Studio
          </p>
          <img src={landingPageAILogo} alt="landingPageAILogo" />
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
