import Appointment from "./Appointment";
import BackButton from "./BackButton";
import Calendly from "./Calendly";
import ChatBox from "./ChatBox";
import Dashboard from "./Dashboard";
import HistoryDashboard from "./HistoryDashboard";
import Home from "./Home";
import LandingPage from "./LandingPage";
import LawyerDetails from "./LawyerDetails";
import LawyerList from "./LawyerList";
import LawyerProfileCard from "./LawyerProfileCard";
import Sidebar from "./Sidebar";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import TableList from "./TableList";
import TryCourtGPTDashboard from "./TryCourtGPTDashboard";

export {
  Home,
  Dashboard,
  LawyerList,
  LawyerDetails,
  Sidebar,
  TableList,
  Appointment,
  LawyerProfileCard,
  Calendly,
  ChatBox,
  SignUp,
  SignIn,
  BackButton,
  HistoryDashboard,
  LandingPage,
  TryCourtGPTDashboard,
};
