import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";

const CheckAuth = ({ children }) => {
  const { authSlice } = useSelector((state) => state);
  const { isLoggedIn } = authSlice.user;

  return isLoggedIn ? children : <Navigate to="/" />;
};

CheckAuth.propTypes = {
  children: PropTypes.element.isRequired,
};

export default CheckAuth;
