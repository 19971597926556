import React, { useEffect, useRef, useState } from "react";
import { IoSend } from "react-icons/io5";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";

import { toast } from "react-hot-toast";

import ChatBox from "./ChatBox";
import Prompts from "./Prompts";
import { storeGuestSession } from "../features/authSlice";

const TryCourtGPTDashboard = ({ connect }) => {
  const [query, setQuery] = useState("");
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);

  const [chats, setChat] = useState([]);

  const chatBoxRef = useRef();
  const inputRef = useRef();
  const socketRef = useRef(null);

  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { authSlice } = store;

  const sessionId = authSlice.session.guestId;

  const createSession = async () => {
    const session_name = "test";

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/guest/session?session_name=${session_name}`
      );

      if (response.statusText === "OK") {
        const { session_id } = response.data.data;
        dispatch(storeGuestSession(session_id));
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 409) {
          toast.error(`${error.response.data.errorData.message}`);
        } else if (error.response.status === 401) {
          toast.error(`${error.response.data.errorData.message}`);
        } else {
          toast.error(`${error.response.data.errorData.message}`);
        }
      } else if (error.request) {
        toast.error(`No Response received`);
      } else {
        toast.error(`No Response received`);
      }
    }
  };
  useEffect(() => {
    createSession();
  }, [dispatch]);

  useEffect(() => {
    if (connect) {
      socketRef.current = new WebSocket(
        `${process.env.REACT_APP_WEBSOCKET_BASE_API_URL}/ws/guest/${sessionId}`
      );

      socketRef.current.onopen = () => {};

      socketRef.current.onmessage = (event) => {
        const message = event.data;

        const msgObj = JSON.parse(message);

        if (msgObj.type === "start") {
          setLoading(true);
        }

        if (msgObj.type === "stream") {
          if (msgObj.sender_type === "BOT") {
            setChat((prevChat) => {
              const updatedChat = [...prevChat];

              const lastChatIndex = updatedChat.length - 1;

              if (lastChatIndex >= 0) {
                updatedChat[lastChatIndex] = {
                  ...updatedChat[lastChatIndex],
                  id: uuidv4(),
                  userQuery: query,
                  response: {
                    ...updatedChat[lastChatIndex].response,
                    message:
                      updatedChat[lastChatIndex].response.message +
                      msgObj.message,
                  },
                  isCompleted: false,
                };
              } else {
                updatedChat.push({
                  id: uuidv4(),
                  userQuery: query,
                  response: {
                    message: msgObj.message,
                  },
                  isCompleted: false,
                });
              }

              return updatedChat;
            });
          }
          setQuery("");
        }

        if (msgObj.type === "end") {
          setLoading(false);
          setQuery("");
          setChat((prevChat) => [
            ...prevChat,
            {
              userQuery: "",
              response: {
                message: "",
              },
              isCompleted: true,
            },
          ]);
        }
      };

      socketRef.current.onclose = () => {
        // need to remove the session id HERE
      };
    }
    return () => {
      if (socketRef.readyState === 1) {
        socketRef.current.close();
      }
    };
  }, [query, connect]);

  useEffect(() => {
    chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
  }, [chats]);

  const handleChange = (e) => {
    setQuery(e.target.value);
    setInput(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (query.length < 2) {
      return;
    }

    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify({ message: query }));
    }

    setLoading(false);
  };

  const handlePromptQuery = (promptQuery) => {
    setQuery(promptQuery);
  };

  const handleEdit = () => {
    setQuery(input);
  };

  return (
    <main className="dashboard-wrapper">
      <div className="try-chat-box scroll-smooth" ref={chatBoxRef}>
        {chats.length > 0 ? (
          chats.map((chat) => {
            if (chat.isCompleted || !chat.response.message) {
              return null;
            }
            return (
              <ChatBox
                loading={loading}
                key={chat.id}
                query={query}
                chat1={chat}
                handleEdit={handleEdit}
              />
            );
          })
        ) : (
          <Prompts handlePromptQuery={handlePromptQuery} />
        )}
      </div>

      <form className="chat-form" onSubmit={handleSubmit}>
        <input
          ref={inputRef}
          className="chat-input"
          name="chatInput"
          value={query}
          type="text"
          onChange={handleChange}
          disabled={loading}
          autoComplete="off"
          placeholder="Write a prompt..."
        />

        {loading ? (
          <div className="loader">
            <BeatLoader
              color="#f1f1f1"
              loading={loading}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <div className="inp-btn">
            <IoSend
              className="bg-primary p-1 cursor-pointer"
              color="black"
              size={25}
              onClick={handleSubmit}
            />
          </div>
        )}
      </form>
    </main>
  );
};

TryCourtGPTDashboard.propTypes = {
  connect: PropTypes.bool.isRequired,
};

export default TryCourtGPTDashboard;
