import axios from "axios";
import React from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import { showHistory } from "../features/chatSlice";

const RecentChats = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { chatSlice } = store;
  const { authSlice } = store;

  const { recentChat } = chatSlice;

  const { token } = authSlice.user;

  const getAllChats = async (session_id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const page = 1;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/chats?session_id=${session_id}&page=${page}`,
        { headers }
      );

      if (response.statusText === "OK") {
        dispatch(showHistory(response.data.data.messages));
        navigate(`/home/chat/${session_id}`);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 409) {
          toast.error(`${error.response.data.errorData.message}`);
        } else if (error.response.status === 401) {
          toast.error(`${error.response.data.errorData.message}`);
        } else {
          toast.error(`${error.response.data.errorData.message}`);
        }
      } else if (error.request) {
        toast.error(`No Response received`);
      } else {
        toast.error(`No Response received`);
      }
    }
  };

  const handleRecentChat = (session_id) => {
    getAllChats(session_id);
  };

  const sortedChat = recentChat.slice().sort((a, b) => {
    const timeA = moment(a.last_message_updated_at).valueOf();
    const timeB = moment(b.last_message_updated_at).valueOf();

    return timeB - timeA; // Sort in descending order (latest time first)
  });

  return (
    <ul className="recentBox flex flex-col transition-all">
      {sortedChat.map(
        ({
          session_name,
          first_message,
          last_message_updated_at,
          session_id,
        }) => {
          const time = moment(last_message_updated_at).fromNow(); // timeStamps to readableTime

          return (
            <li
              key={session_id}
              className="recentItem flex flex-col my-2"
              onClick={() => handleRecentChat(session_id)}
            >
              <span className="flex justify-between md:text-base text-sm">
                <h3 className="font-semibold">
                  {session_name.length > 15
                    ? `${session_name.substring(0, 10)} ...`
                    : session_name}
                </h3>
                <p className="text-sm">{time}</p>
              </span>
              <span className="text-sm mt-2 text-slate-400 ">
                {`${`${first_message.substring(0, 60)}...`}`}
              </span>
            </li>
          );
        }
      )}
    </ul>
  );
};

export default RecentChats;
