import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InlineWidget } from "react-calendly";
import { cancel } from "../assets";
import BackButton from "./BackButton";

const Calendly = () => {
  const { authSlice } = useSelector((state) => state);

  const email = authSlice.user.userName;
  const name = authSlice.user.userName.split("@")[0];

  const navigate = useNavigate();
  return (
    <div className="appointment-book-wrapper">
      <BackButton />
      <header className="head">
        <h2 className="font-bold">Book Appointment</h2>
        <img
          className="h-[30px] z-40 cursor-pointer relative lg:right-5 md:right-16 right-20 p-1 bg-transparent border rounded-full hover:scale-105"
          src={cancel}
          alt="close-icon"
          onClick={() => navigate("/home")}
        />
      </header>

      <InlineWidget
        url={`${process.env.REACT_APP_CALENDLY_API_URL}/consultation`}
        className="calendly-component"
        styles={{
          height: "100%",
          position: "relative",
          top: "-60px",
        }}
        pageSettings={{
          backgroundColor: "#1C1D23",
          primaryColor: "#E9FE53",
          textColor: "#ffffff",
        }}
        prefill={{
          email,
          name,
        }}
      />
    </div>
  );
};
export default Calendly;
